import React from "react"

import Header from "../components/header/"
import Main from "../components/main/"

import "../styles/globals/index.scss"

const Layout = ( props ) => {

	return (
		<div id={`root`}>

			<Main>{ props.children }</Main>
		</div>
	)
}

export default Layout