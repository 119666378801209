import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const ChurchFarmPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			image01: file(relativePath: { eq: "properties/church-farm/church-farm-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Church Farm"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/w-lamb/current-developments`}/>
				<Split>
					<ImageBlock 
						image={ data.image01.childImageSharp.fluid }/>
					<TextBlock 
						title={`Church farm`}
						titleTag={ `h1` }
						text={`
							<h2>Aldbury, Hertfordshire</h2>
							<p>Planning Permission is being sought to sympathetically convert the old stables into a mixed-use development. Working within the confines of a Conservation Area, W Lamb Ltd will manage both the development and the subsequent property rental management.</p>
							<p>For any enquiries, please email <a href="mailto:admin@painesend.com" target="_blank">admin@painesend.com</a></p>
						`}
						bgColour={wlColour.slug}/>

				</Split>
			</Layout>
		</>
	)
}

export default ChurchFarmPage
