import React from "react"
import Img from "gatsby-image"

import styles from "./style.module.scss"

const ImageBlock = ( props ) => {
	
	return (
		
		<div className={ styles.block }>
			<Img className={ styles.image } fluid={ props.image } imgStyle={{objectPosition: props.position }}/>
		</div>
		
	)
}

export default ImageBlock
