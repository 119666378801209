import React from "react"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import styles from "./style.scss"

const Crumbs = (props) => {
	
	const {
    	breadcrumb: { crumbs },
  	} = props.pageContext
	const customCrumbLabel =  props.location.pathname.replace('/', ' ')
	const output = customCrumbLabel.substring(customCrumbLabel.lastIndexOf('/') + 1)

	return (

		<div className={`breadBox ${ props.full ? 'breadBox--full' : '' }`}>
			<div className={`breadBox__content`}>
				<Breadcrumb
					className={styles.breadcrumbItem}
					crumbs={crumbs}
					crumbSeparator=" / "
					crumbLabel={output}/>
			</div>
		</div>

	)
}
export default Crumbs
