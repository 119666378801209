import React from "react"

import styles from "./style.module.scss"

const Main = (props) => (
	
    <main className={ styles.container }>
		{props.children}
    </main>
  
)

export default Main