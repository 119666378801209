import React, { useEffect } from "react"

import Img from "gatsby-image"

import "../../styles/inline/themes.scss"
import styles from "./style.module.scss"

const TextBlock = ( props ) => {
	
	return (
		
		<section className={ styles.block }>
			<div className={ `${ styles.overlay } background--${ props.bgColour }`}>
				<div className={ styles.content }>
					{ props.title &&
						React.createElement(
							props.titleTag, 
							{
								className: styles.title,
							}, 
							props.title,
						) 
					}
					{ props.text &&
						<div className={ props.columns } dangerouslySetInnerHTML={{ __html: props.text }}></div>
					}
				</div>
			</div>
			{ props.bgImage && 
				<Img className={ styles.background } 
					 fluid={ props.bgImage } 
					 imgStyle={{objectPosition: props.position }}/>
			}
		</section>
		
	)
}

export default TextBlock
