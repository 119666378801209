import React from "react"

import { Link } from "gatsby"

import styles from "./style.module.scss"

const BackNav = ( props ) => {
	
	return (
		
		<Link 
			to={ props.link }
			className={ styles.backNav }
			bg={ props.hex }>
			<p className={ styles.text }>&lt;</p>
		</Link>
		
	)
}

export default BackNav
